/* global URL_BASE */
import axios from 'axios';

import appendFiles from './append';
import frontendsIterator from './frontendsIterator';

const getProductionBaseUrl = (name, version) => {
  let url;

  if (name && version) {
    url = `${URL_BASE}/${name}/${version}`;
  }
  return url;
};

const loadManifest = (name, version) => {
  const url = getProductionBaseUrl(name, version);

  if (!url) {
    console.error(`Something went wrong loading ${name}:v${version}`);
    return;
  }

  axios
    .get(`${url}/manifest.json`)
    .then(appendFiles.bind(this, url, name))
    .catch(error => console.error(error));
};

window.loadMicrofrontends = () => {
  frontendsIterator(frontend => {
    const { name, version } = frontend;
    if (name) {
      loadManifest(name, version);
    }
  });
};

window.loadMicrofrontends();
