/* global MF_CLASSNAME */
import { map, uniqWith } from 'lodash';

export default callback => {
    // get all elements with specific classname
    const htmlCollection = document.getElementsByClassName(MF_CLASSNAME);

    // parse those elements data attributes
    const frontends = map(htmlCollection, element => element.dataset);

    // compare objects only by name property
    const equalByName = (e1, e2) => e1.name === e2.name;

    uniqWith(frontends, equalByName).forEach(callback);
};
