import { endsWith, forEach } from 'lodash';

export const appendScript = (url, elementId) => {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.src = url;
  script.setAttribute('id', elementId);

  document.body.appendChild(script);
};

export const appendStyle = (url, elementId) => {
  const style = document.createElement('link');

  style.rel = 'stylesheet';
  style.href = url;
  style.setAttribute('id', elementId);

  document.head.appendChild(style);
};

export default (url, name, { data: manifest }) => {
  forEach(manifest, (value, key) => {
    const scriptElementId = `${name}-microfrontend-script`;
    const stylesheetElementId = `${name}-microfrontend-stylesheet`;

    if (endsWith(key, 'app.js') && !document.getElementById(scriptElementId)) {
      appendScript(`${url}/${value}`, scriptElementId);
    }
    if (endsWith(key, '.css') && !document.getElementById(stylesheetElementId)) {
      appendStyle(`${url}/${value}`, stylesheetElementId);
    }
  });
};
